/*

  purple: rgb(30,2,50)
  orange: rgb(174,83,0)
  beige: rgb(217,192,185)

*/

.test-class {
  font-style:normal;
  
}


.single-services {
    position: relative;
    display:inline-block;
}

.single-services .overlay-info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    transition: background 500ms ease-in-out;
}

.single-services:hover .overlay-info{
  background: rgba(20,20,20,0.30);
}

.test{
  text-align: right;
  padding-right: 1em;
  padding-inline: 2em;
  align-items: center;
  vertical-align: middle;
  font-style: italic;
}

.fixingSize{
  height: 88vh;
}

.fixingNav{
  height: 9.5vh;
  background: rgb(30,2,50);
  width: 100%;
  padding-inline: 25em !important;
  color: white;
}


.fixingCaption{
  padding-bottom: 35vh !important;
}

.fixingContactInfo{
  border-right: solid 3px white;
  padding-left: 25% !important;
  height: 70%;
  text-align: center;
}

.fixingMap{
  text-align: left !important;
  padding-left: 3em !important;
}

.fixingSocials{
  width: 60%;
  text-align: center;
  padding-top: 1em;
}

@media(max-width: 1366px){
  .fixingSize{
    height: 85vh;
  }

  .fixingCaption{
    padding-bottom: 35vh !important;
  }

  .fixingNav{
    padding-inline: 10em !important;
  }
  
  .fixingNavRes{
    text-align: end;
    margin-top: 0em;
  }

  .fixingContactInfo{
    height: 45vh !important;
    padding-right: 3em !important;
  }
  
  .fixingMap{
    padding-top: 6vh !important;
    text-align: left !important;
    padding-left: 3em !important;
  }

}

@media(max-width: 768px){
  .fixingSize{
    height: 68vh;
  }

  .fixingCaption{
    padding-bottom: 8vh !important;
  }

  .fixingNav{
    height: auto;
    width: 100%;
    
    padding-inline: 0em !important;
  }
  
  .fixingNavRes{
    text-align: end;
    padding-top: 2em;
  }

  .fixingContactInfo{
    height: 40vh !important;
    padding-left: 0% !important;
    padding-right: 0em !important;
  }

  .fixingSocials{
    width: 100%;
  }
  
  .fixingMap{
    padding-top: 50px !important;
    text-align: center !important;
    padding-left: 0.8em !important;
  }

  .fixingAboutImg {
    object-fit: contain;
  }

}


